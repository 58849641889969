<template>
  <div>
    <div class="mb-3">
      ebay: {{ ebayCode }}
      userToken: {{ ebayUserToken}}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useAuth0 } from "@auth0/auth0-vue";

const store = useStore()
const route = useRoute()
const router = useRouter()
const auth0 = useAuth0()

const ebayCode = computed(() => store.state.eshop.ebayCode)
const ebayUserToken = computed(() => store.state.eshop.ebayUserToken)
const code = route.query.code || null
const state = route.query.state || null


if (code) {
  store.commit('eshop/setEbayCode', code)
  getToken()
}

if (state) {
  router.replace(`/store/${state}/settings`)
} else {
  //router.replace({name: 'Home'})
}

function getToken() {
  //error.value = ''
  store.dispatch('eshop/getEbayUserToken', {auth0, code: ebayCode.value})
    .catch(e => {
      console.log('getUserToken error', e)
    })
}

// function saveCode() {
//   if (!ebayCode.value || !eshopStore.value) return
//   store.dispatch('eshop/saveStoreConfig', {auth0, key: 'EBAY_USER_ACCESS_TOKEN', value: ebayCode.value})
// }
</script>
